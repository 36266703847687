<template>
    <div class="qrcode">
    </div>
</template>
<script>
    export default {
        name:'index',
        data() {
            return {
                code:this.$route.query.code,
                platform:this.$route.query.platform,
                activityid:this.$route.query.activityid,
            }
        },
        created() {
            let _this = this;
            // console.log(_this.code);
            if (typeof(_this.code) != 'undefined') {
                _this.getUserInfo(_this.code, _this.platform, _this.activityid);
            } else {
                this.$toast('非企业员工无法正常登录！');
                // _this.$router.push({
                //     path: '/'
                // })
                // return false;
            }
        },
        methods: {
            getUserInfo(code, platform,activityid) {
                this.$toast.loading({
                  message: '登录中...',
                  forbidClick: true,
                });
                this.$post('live/user/wechat', {code:code,platform:platform,activityid:activityid}).then(res =>{
                    // localStorage.setItem('token', res.data.data.token);
                    var back_url = res.data.data.back_url;
                    this.$toast.setDefaultOptions({ duration: 1000 });
                    this.$toast.success(res.data.msg);
                    setTimeout(res => {
                        window.location.href = back_url;
                        // this.$router.push({
                        //     path: back_url
                        // })
                    }, 1000);
                })
            }
        },
    }
</script>
